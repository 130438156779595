import React from "react";

const SectorDetails = ({ details }) => {
  const scope = details.filter((detail) => detail[0] === "scope");
  return (
    <div className="relative z-10 py-16 bg-bobalife md:w-[96vw] 2xl:w-[90vw] max-w-[1700px] md:mx-auto">
      <div className="md:container mx-6 md:mx-auto md:px-6 lg:px-0">
        <div className="text-xl tracking-wider font-bold uppercase pb-2 border-b-2 border-cinnabar-500 mb-5">
          Project details
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="grid grid-cols-2 gap-4">
            {details.map((item, i) => {
              if (item[0] !== "scope") {
                return (
                  <div key={i}>
                    <div className="text-cinnabar tracking-wider font-light uppercase">
                      {item[0]}
                    </div>
                    <div className="font-bold uppercase tracking-wider">
                      {item[1]}
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="w-full md:w-auto">
            <div className="text-cinnabar tracking-wider font-light uppercase">
              Scope
            </div>
            <div className="font-light tracking-wider">{scope[0][1]}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectorDetails;
