import React from "react";
import { graphql } from "gatsby";
import { BsArrowLeft } from "react-icons/bs";

import { Link } from "gatsby";
import Layout from "../components/commercial/Layout";
import SectorDetails from "components/SectorDetails";
import Gallery from "components/Gallery";
import NextSection from "components/NextSection";

export const query = graphql`
  query ($id: ID!) {
    wp {
      sector(id: $id) {
        id
        title
        singleSector {
          detail {
            client
            duration
            location
            scope
            value
          }
          gallery {
            ...MediaItem
          }
          nextSection {
            number
            label
            link {
              ... on WP_Page {
                id
                uri
              }
            }
            image {
              ...MediaItem
            }
            heading
          }
        }
        content
      }
    }
  }
`;

const Sector = ({ data }) => {
  if (!data) return null;
  const { title, content } = data.wp.sector;
  const { detail, gallery } = data.wp.sector.singleSector;
  const fields = data.wp.sector.singleSector.detail;
  return (
    <Layout title={title}>
      <div>
        <div className="absolute top-0 left-0 bottom-0 z-0 h-2/3 md:h-1/2 w-full md:w-7/12 lg:w-[45%] xl:w-7/12 bg-everglade"></div>
        <div className="md:w-[96vw] 2xl:w-[90vw] max-w-[1700px] mx-6 md:mx-auto text-center text-white">
          <header></header>
          <div className="mt-32">
            <h1 className="relative font-bold uppercase text-left text-white leading-none text-[40px] tracking-wider mb-4 md:max-w-[55%]">
              {title}
            </h1>
            <h3 className="text-white relative text-left mb-4">
              {fields.location}
            </h3>
            {gallery && (
              <Gallery items={gallery} theme="cinnabar" className={`h-2/3`} />
            )}
          </div>
        </div>
        {/* Gallery */}
        {/* Details */}
        <SectorDetails details={Object.entries(detail)} />
        {/* Content */}
        <div className="md:container mx-6 md:px-6 lg:px-0 md:mx-auto my-20">
          <div
            className="prose space-y-6"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
          <div className="mt-16 flex gap-16">
            {/* Link to sectors page */}
            <Link
              href="/commercial/sectors"
              className={`flex items-center text-sm font-bold hover:underline text-cinnabar`}
            >
              <BsArrowLeft className={`mr-2 text-xl`} />
              Back to sectors
            </Link>
            {/* Different sector */}
            {/* <div
              className={`flex items-center text-sm font-bold hover:underline text-cinnabar`}
            >
              Industrial
              <BsArrowRight className={`ml-2 text-xl`} />
            </div> */}
          </div>
        </div>
        {data.wp.sector.singleSector.nextSection.number && (
          <NextSection
            number={data.wp.sector.singleSector.nextSection.number}
            label={data.wp.sector.singleSector.nextSection.label}
            heading={data.wp.sector.singleSector.nextSection.heading}
            background="bobalife"
            link={
              data.wp.sector.singleSector.nextSection.link &&
              data.wp.sector.singleSector.nextSection.link.uri
            }
            image={
              data.wp.sector.singleSector.nextSection.link &&
              data.wp.sector.singleSector.nextSection.image
            }
          />
        )}
      </div>
    </Layout>
  );
};

export default Sector;
